<i18n>
ru:
  dateLabel: День
  hourLabel: Час
  minuteLabel: Минута
  period: Период
ua:
  dateLabel: Дата
  hourLabel: Час
  minuteLabel: Хвилини
  period: Період
us:
  dateLabel: Day
  hourLabel: Hour
  minuteLabel: Minute
  period: Period
</i18n>

<template>
  <div
    class="v-row"
    v-if="
      clientStore.ClientState.data?.LimitOrderPeriod?.Scheduled
        ?.EnabledByTerminalSettings
    "
  >
    <div class="v-col-12 v-col-md-6">
      <ui-select
        required
        :label="translate('deliveryTimeRathloriel.dateLabel')"
        :options="
          Object.keys(
            clientStore.ClientState.data.LimitOrderPeriod.Scheduled.Schedule.Dates
          )
        "
        v-model:selected="day"
      >
        <template #option="option">
          <span v-html="makeDateText(sanitize(option.value))" />
        </template>
      </ui-select>
    </div>

    <div
      class="v-col-6"
      v-if="clientStore.ClientState.data.LimitOrderPeriod.Scheduled.ByPeriods"
    >
      <ui-select
        required
        :disabled="!day"
        :label="translate('deliveryTimeRathloriel.period')"
        :options="Object.keys(periods)"
        v-model:selected="period"
        @change="saveTime"
      >
        <template #option="option">
          <span v-html="sanitize(option.value)" />
        </template>
      </ui-select>
    </div>
    <template v-else>
      <div class="v-col-6 v-col-md-3">
        <ui-select
          required
          :disabled="!day"
          :label="translate('deliveryTimeRathloriel.hourLabel')"
          :options="Object.keys(hours)"
          v-model:selected="hour"
        >
          <template #option="option">
            <span v-html="sanitize(option.value)" />
          </template>
        </ui-select>
      </div>

      <div class="v-col-6 v-col-md-3">
        <ui-select
          required
          :disabled="!hour"
          :label="translate('deliveryTimeRathloriel.minuteLabel')"
          :options="Object.keys(minutes)"
          v-model:selected="minute"
          @change="saveTime"
        >
          <template #option="option">
            <span v-html="sanitize(option.value)" />
          </template>
        </ui-select>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { MinuteOrPeriod } from '~types/clientStore'

const appConfig = useAppConfig()

const { sanitize, translate } = useI18nSanitized()
const clientStore = useClientStore()
const { fromMillisInZone, makeDateText } = useDateTime()

const day = ref<string | null>(null)
const hour = ref<string | null>(null)
const minute = ref<string | null>(null)
const period = ref<string | null>(null)

const periods = computed<Record<string, MinuteOrPeriod>>(() =>
  day.value
    ? (clientStore.ClientState.data?.LimitOrderPeriod?.Scheduled?.Schedule?.Dates[
        day.value
      ].Periods ?? {})
    : {}
)

const hours = computed<Record<string, Record<string, MinuteOrPeriod>>>(() => {
  return day.value
    ? (clientStore.ClientState.data?.LimitOrderPeriod?.Scheduled?.Schedule?.Dates[
        day.value
      ]?.Hours ?? {})
    : {}
})

const minutes = computed<Record<string, MinuteOrPeriod>>(() =>
  day.value && hour.value
    ? (clientStore.ClientState.data?.LimitOrderPeriod?.Scheduled?.Schedule?.Dates[
        day.value
      ]?.Hours ?? {})[hour.value]
    : {}
)

function saveTime(): void {
  if (!clientStore.ClientState.data) return

  if (clientStore.ClientState.data.LimitOrderPeriod.Scheduled.ByPeriods) {
    if (period.value)
      clientStore.updateOrderData({
        deliveryTime: periods.value[period.value].Date,
        refreshState: true
      })
  } else if (minute.value)
    clientStore.updateOrderData({
      deliveryTime: minutes.value[minute.value].Date,
      refreshState: true
    })
}

function refreshFields(): void {
  if (!clientStore.ClientState.data) return

  const time: number | undefined =
    clientStore.ClientState.data.StateOrderData?.DeliveryTime
  if (time) {
    const dt = fromMillisInZone(time * 1000, appConfig.RestaurantSettingsPreRun.GMT)
    const dayLocal = dt.toISODate({ format: 'basic' })
    day.value = dayLocal

    if (clientStore.ClientState.data.LimitOrderPeriod.Scheduled.ByPeriods) {
      if (dayLocal)
        for (const [key, value] of Object.entries(
          clientStore.ClientState.data?.LimitOrderPeriod?.Scheduled?.Schedule?.Dates[
            dayLocal
          ].Periods
        )) {
          if (value.Date === time) {
            period.value = key
            break
          }
        }
    } else {
      hour.value = dt.hour.toString()
      minute.value = dt.minute.toString()
    }
  }
}

onMounted(refreshFields)

watch(
  () => clientStore.ClientState.data?.Version ?? null,
  (newContent: number | null, oldContent: number | null) => {
    if (oldContent === null) return // after initialization oldContent is null
    if (newContent === null) return // in case of client state update error

    if (oldContent !== newContent) refreshFields()
  }
)
</script>
